import React from 'react'
import PropTypes from 'prop-types'
import {
  Link,
  graphql
} from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'
import NewsRoll from "../components/NewsRoll"

import bg from "../img/bg.jpg";

import inputcone from "../img/inputcone.jpg";
import golden from "../img/about/golden2.jpg"
import sslex from "../img/about/sslex.jpg"
import kyosan from "../img/about/kyosan.jpg"
import anaze from "../img/about/naze.jpg"
import dream from "../img/about/dream.jpg"

const About = () => (
  <Layout>
    <section className="section section--gradient">
             <div className="content">
               <div className="columns">
                 <div className="column is-10 is-offset-1">
                   <div className="content">
                     <div
                       className="column is-8 is-offset-2"
                       style={{
                         marginBottom: `80px`
                       }}
                     >
                       <div
                         class="about"
                         data-sal="fade"
                         data-sal-duration="50"
                         data-sal-delay="50"
                         data-sal-easing="ease-in-cubic"
                       >
                         <img
                           className="margin-btm-0  column is-8 is-offset-2"
                           src={inputcone}
                           alt=""
                           width="100%"
                           height="auto"
                         />
                         <b>
                           <span class="text1">ロ</span>
                           <span class="text2">ボカップへの挑戦</span>
                         </b>
                         <p className="is-size-6">
                           私たちINPUTは「ロボカップ」という、ロボット自らが考えて動く自律移動型ロボットの大会のサッカー小型ロボットリーグ(Small Size League)に挑戦しています。NPO法人長岡産業活性化協会NAZEの会員企業を中心とした長岡の地元企業とのロボット開発を通じて、地域活性化を推進するロールモデルを構築しました。ロボカップ、サッカー小型ロボットリーグ、NAZEの詳細に関しては以下のリンクを参照してください。
                         </p>
                         <a href="http://www.robocup.or.jp/" target="_blank">
                           RJCロボカップ日本委員会公式ホームページリンク
                         </a>
                         <br />
                         <a href="http://www.naze.biz/" target="_blank">
                           NPO法人長岡産業活性化協会NAZE公式ホームページリンク
                         </a>
                       </div>

                       <div
                         class="about"
                         data-sal="fade"
                         data-sal-duration="50"
                         data-sal-delay="50"
                         data-sal-easing="ease-in-cubic"
                       >
                         <img
                           className="margin-btm-0"
                           src={golden}
                           alt=""
                           width="100%"
                           height="auto"
                         />
                         <b>
                           <span class="text1">黄</span>
                           <span class="text2">金時代、その先へ</span>
                         </b>
                         < p className = "is-size-6" >
                           INPUTは、もともと20歳以下の大会であるジュニアリーグに2016年から3年連続で世界大会の出場を果たした長岡高専のロボットチームのメンバーを中心に構成されました。ジュニアリーグでは好成績を収めた私たちですが、年齢制限のためジュニアリーグを引退せざるを得なくなりました。しかし、自分たちの培ってきた技術を生かしたい、長岡をホームにロボットを作り続けたいという思いから、メジャーリーグのサッカー小型ロボットリーグの挑戦を決意しました。
                         </p>
                       </div>
                       <div
                         class="about"
                         data-sal="fade"
                         data-sal-duration="50"
                         data-sal-delay="50"
                         data-sal-easing="ease-in-cubic"
                       >
                         <img
                           className="margin-btm-0"
                           src={sslex}
                           alt=""
                           width="100%"
                           height="auto"
                         />
                         <b>
                           <span class="text1">壁</span>
                           <span class="text2">は高けれど</span>
                         </b>
                         < p className = "is-size-6" >
                           私たちが挑戦するメジャーリーグはジュニアリーグとは違い、大学などの研究機関や社会人が参加する大会です。競技内容も実際のサッカーに近いレベルの高いもので、資金面や技術面で学生だけが集まってチームを結成するのは困難でした。これが原因で多くのジュニア部門参加者が20歳を超えるとロボカップを引退してしまいます。
                         </p>
                       </div>
                       <div
                         class="about"
                         data-sal="fade"
                         data-sal-duration="50"
                         data-sal-delay="50"
                         data-sal-easing="ease-in-cubic"
                       >
                         <img
                           className="margin-btm-0"
                           src={kyosan}
                           alt=""
                           width="100%"
                           height="auto"
                         />
                         <b>
                           <span class="text1">長</span>
                           <span class="text2">岡で旗挙げを</span>
                         </b>
                         < p className = "is-size-6" >
                           行き詰まった私たちを救ってくれたのがNAZE及び、私たちの地元長岡地域を中心とするの協賛企業さん、私達の夢を応援し、寄付をしてくださった方々でした。新たなチームメンバーとして地元長岡の高い技術力を持つ企業に協力してもらい、長岡地域が一丸となってロボットを作る新たなチーム「INPUT」を結成し、NAZEの新しい事業としてロボカップでもう一度世界一になる挑戦をスタートさせました。
                         </p>
                       </div>
                       <div
                         class="about"
                         data-sal="fade"
                         data-sal-duration="50"
                         data-sal-delay="50"
                         data-sal-easing="ease-in-cubic"
                       >
                         <img
                           className="margin-btm-0"
                           src={dream}
                           alt=""
                           width="100%"
                           height="auto"
                         />
                         <b>
                           <span class="text1">夢</span>
                           <span class="text2">と希望と恩返し</span>
                         </b>
                         < p className = "is-size-6" >
                           私たちがこうして世界一を目指すのには二つ理由があります。一つ目は小中学生に夢と目標を与えることです。私たちがそうだったようにロボットへ興味や関心が新しい世代の技術者を作る礎となると信じています。もう一つはこの活動を通じて技術分野における発展に貢献していくことす。私たちを育ててくれた長岡地域の技術力を世界にアピールするだけでなく、この活動を通じて長岡地域の企業がもっと活性化するような機運づくりをし、日本・世界の技術分野における発展に貢献していきます。
                         </p>
                       </div>
                     </div>
            </div>
            </div>
               </div>
             </div>
           </section>
  </Layout>
)

export default About